import {ref, watch} from '@vue/composition-api'

// Notification
import moodsRepository from '@/abstraction/repository/moodsRepository'

const repository = new moodsRepository()
export default function usemoodList() {
    const refplaylistListTable = ref(null)
    const playlists = ref([])
    const perPage = ref(10)
    const perPageOptions = [10, 25, 50, 100]
    const sortBy = ref('id')
    const currentPage = ref(1)
    const searchQuery = ref('')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)
    const pagination = ref({})

    const refetchData = () => {
        refplaylistListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData()
    })

    // Table Handlers
    const tableColumns = [
        { key: 'list', sortable: true },
        { key: 'music', sortable: true },
        { key: 'genre', sortable: true },
        { key: 'share_count', sortable: true },
        { key: 'actions' },
    ]

    const storePlaylist = async data => {
        try {
            return await repository.storeWithSlug(data)
        } catch (e) {
            return false
        }
    }

    const showPlaylist = async id => {
        try {
            return await repository.show(id)
        } catch (e) {
            return false
        }
    }

    const updatePlaylist = async (id, data) => {
        try {
            return await repository.update(id, data)
        } catch (e) {
            return false
        }
    }

    const fetchPlaylist = async (paginate = {page: 1, itemsPerPage: perPage.value}, filters= {}) => {
        const playlistsList = await repository.index({filters, pagination: paginate})
        playlists.value = playlistsList.data
        pagination.value = playlistsList.pagination
        return playlists
    }


    const deletePlaylist = async (id) => {
        try {
            const response = await repository.delete(id)
            pagination.value.itemsLength--
            const index = playlists.value.findIndex(x => x.id === id)
            playlists.value.splice(index, 1)
            return response
        } catch (e) {
            return false
        }
    }

    const changePagePlaylist = async page => {
        const response = await fetchPlaylist({pagination: page})
        return response
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        tableColumns,
        playlists,
        pagination,
        perPageOptions,
        perPage,
        sortBy,
        currentPage,
        storePlaylist,
        showPlaylist,
        updatePlaylist,
        fetchPlaylist,
        deletePlaylist,
    }
}
