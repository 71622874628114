<template>
  <div v-if="form">
    <!-- First Row -->
    <b-row>
      <b-col cols="12">
        <play-list-view-play-list-info-card :play-list-data="form" />
      </b-col>
    </b-row>
    <template v-if="form.music.length">
      <div class="d-flex justify-content-start align-items-center">
        <b-card bg-variant="danger" text-variant="white">
          <i class="WMi-list-ul-2" />
          Moods
        </b-card>
      </div>
      <b-row>
        <b-col cols="12" sm="4" md="4" v-for="item in form.music" :key="item.id">
          <b-link :to="{ name: 'apps-moods-edit', params: { id: item.id } }">
            <play-list-view-tab-data :item="item" />
          </b-link>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import MoodsRepository from "@/abstraction/repository/moodsRepository";
const MoodRepository = new MoodsRepository();
// Local Components
 import  PlayListViewPlayListInfoCard from './PlayListViewPlayListInfoCard';
  import PlayListViewTabData from './PlayListViewTabData';
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BCard,
  BTabs,
  BTab,
  BCardSubTitle,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BTabs,
    BTab,
    BCardSubTitle,
    BCardTitle,
    BCardBody,
    // Local Components
    PlayListViewPlayListInfoCard,
    PlayListViewTabData,
  },
  setup() {
    const form = ref(null);
    const moodId = ref(router.currentRoute.params.id);
    onMounted(async () => {
      if (moodId.value) {
        form.value = await MoodRepository.show(moodId.value);
      }
    });
    return {
      form,
    };
  },
};
</script>

<style></style>
