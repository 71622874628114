<template>
    <b-card v-if="playListData">
        <!-- User Info: Left col -->
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start align-items-center">
            <b-avatar :src="playListData.thumbnail" size="104px" rounded />
            <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                    <h4 class="mb-0">
                        {{ playListData.name }}
                    </h4>

                    <span class="card-text">{{ playListData.slug }}</span>
                </div>
                <div class="d-flex flex-wrap">
                    <b-button :to="{ name: 'apps-moods-edit', params: { id: playListData.id } }" variant="danger"> Edit </b-button>
                    <b-button variant="outline-danger" class="ml-1" @click="deletePlaylist(playListData.id)"> Delete </b-button>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import usemoodList from "./useMoodList";

export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
    },
    props: {
        playListData: {
            // type: Object,
            required: true,
        },
    },
    setup() {
        const { resolveUserRoleVariant, deletePlaylist } = usemoodList();
        return {
            avatarText,
            resolveUserRoleVariant,
            deletePlaylist,
        };
    },
    created() {
        console.log("fds", this.playListData);
    },
};
</script>

<style></style>
