<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ item.name }}
        </h2>
        <span>{{ item.artist.name_ar }}</span>
      </div>
      <b-avatar size="45">
        <div
          class="image"
          :style="{
            backgroundImage: `url('${item.thumbnail}')`,
          }"
        />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>
<script>
import {
    BLink, BCard, BCol, BRow, BCardBody, BAvatar,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
    components: {
        StatisticCardHorizontal,

        BLink,
        BCard,
        BCol,
        BRow,
        BCardBody,
        BAvatar,
    },
    props: {
        item: {},
    },
    setup() {},
}
</script>
<style scoped>
.image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}
</style>
